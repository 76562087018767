<template>
  <div>
    <v-card
      v-if="!$vuetify.breakpoint.mobile"
      outlined
      class="my-0 py-0 d-flex flex-column"
      :style="{
        background: 'var(--v-modal-base) !important',
        height: !$vuetify.breakpoint.mobile ? '60vh' : '100vh',
        width: !$vuetify.breakpoint.mobile ? '450px' : '100vw',
        'border-radius': '20px',
      }"
    >
      <v-card-title class="ma-0 pa-0">
        <v-toolbar
          flat
          color="primary"
          dense
          style="border-top-right-radius: 20px; border-top-left-radius: 20px"
        >
          <v-toolbar-title> Comments </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            class="mt-5 pt-2"
            color="white"
            style="width: 5px"
            prepend-inner-icon="filter_alt"
            v-model="filterComments"
            placeholder="Filter"
            :items="['All', 'Internal', 'External']"
            flat
            dense
          ></v-select>
          <v-btn v-if="$vuetify.breakpoint.mobile" text @click="$emit('close')"
            >X</v-btn
          >
        </v-toolbar>
      </v-card-title>

      <v-card-text
        style="min-height: 25vh; max-height: 100%; overflow-y: auto"
        class="mx-0 px-0"
        v-loading="loadingComments"
        v-chat-scroll
        :key="commentKey"
      >
        <v-list dense class="mx-0 px-0">
          <v-list-item
            v-for="(comment, index) in commentList"
            :key="comment.id"
            class="py-0 my-1"
            :id="'comment_' + index"
          >
            <v-list-item-avatar v-if="comment.userId != $store.state.user.id">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    color="secondary"
                    size="48"
                    class="mt-0 pt-0"
                  >
                    <span
                      v-if="!comment.user.avatar"
                      class="primaryText--text text-h5"
                      >{{
                       $Format.avatarAbbreviation(
                          comment.user.firstname + " " + comment.user.surname
                        )
                      }}</span
                    >
                    <img
                      v-else
                      :src="comment.user.avatar"
                      referrerpolicy="no-referrer"
                    />
                  </v-avatar>
                </template>
                <span style="font-size: 12px"
                  >{{ comment.user.firstname }} {{ comment.user.surname }}</span
                >
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-content
              :class="
                comment.userId == $store.state.user.id
                  ? 'text-right'
                  : 'text-left'
              "
            >
              <v-list-item-title class="text-wrap">
                {{ comment.comment }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px; color: grey">
                {{ comment.organisation.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <i style="font-size: 12px; color: grey">{{
                  formatTime(comment.createdAt)
                }}</i>
                <v-chip
                  style="right: 0"
                  outlined
                  v-if="comment.internal"
                  x-small
                  color="blue"
                  class="white--text ml-5"
                  >Internal</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar v-if="comment.userId == $store.state.user.id">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    color="secondary"
                    size="48"
                    class="mt-0 pt-0"
                  >
                    <span
                      v-if="!comment.user.avatar"
                      class="primaryText--text text-h5"
                      >{{
                       $Format.avatarAbbreviation(
                          comment.user.firstname + " " + comment.user.surname
                        )
                      }}</span
                    >
                    <img
                      v-else
                      :src="comment.user.avatar"
                      referrerpolicy="no-referrer"
                    />
                  </v-avatar>
                </template>
                <span style="font-size: 12px"
                  >{{ comment.user.firstname }} {{ comment.user.surname }}</span
                >
              </v-tooltip>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item v-if="commentList.length == 0 && !loadingComments">
            <v-list-item-content class="text-center">
              <span style="font-size: 12px; color: grey"> No comments. </span>
            </v-list-item-content></v-list-item
          >
        </v-list>
        <!-- <v-divider></v-divider> -->

        <!-- <Mentionable
              class="ma-0 pa-0"
              style="width: 100%"
              outlined
              :keys="['@']"
              :items="users"
              @open="onOpen"
              @apply="onApply"
              @search="loadUsers($event)"
              insert-space
            >
              <v-textarea
                rows="2"
                solo
                flat
                class="primaryText--text ma-0 pa-0 mt-2"
                placeholder="Add your comment..."
                v-model="comment"
              />
              <template #no-result>
                <v-list class="my-0 py-0" style="width: 250px" dense>
                  <v-list-item>
                    <v-list-item-action>
                      <v-progress-circular
                        class="mx-1"
                        size="18"
                        color="primary"
                        indeterminate
                        v-if="loadingUsers"
                      ></v-progress-circular>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="loadingUsers">
                        Loading...
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        No result
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <template #item-@="{ item }">
                <v-list class="my-0 py-0" dense>
                  <v-list-item
                    style="height: 25px; cursor: pointer"
                    class="my-0 py-0"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        style="cursor: pointer"
                        color="secondary"
                        size="32"
                        class="mt-0 pt-0"
                      >
                        <span
                          v-if="!item.avatar"
                          class="primaryText--text text-h5"
                          >{{
                           $Format.avatarAbbreviation(
                              item.firstname + " " + item.surname
                            )
                          }}</span
                        >
                        <img
                          v-else
                          :src="item.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.firstname }} {{ item.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </Mentionable>

            <v-col class="my-0 py-0 text-right">
              <v-row align="center" justify="end">
                <v-checkbox
                  v-model="internalComment"
                  label="Internal"
                  class="mr-2"
                ></v-checkbox>
                <v-btn
                  color="primary"
                  class="mt-0 pt-0"
                  style="right: 0"
                  @click="addComment()"
                  text
                  >Comment</v-btn
                >
              </v-row>
            </v-col> -->
      </v-card-text>
      <v-spacer></v-spacer>

      <v-card-actions>
        <v-text-field
            style="width: 100%"
            v-model="comment"
            clearable
            placeholder="Message"
            type="text"
            v-on:keyup.enter="addComment"
          >
            <template v-slot:prepend >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="my-0 py-0"
                    @click="internalComment = !internalComment"
                    :color="internalComment ? 'blue' : 'primaryText'"
                    ><v-icon>vpn_lock</v-icon></v-btn
                  >
                </template>
                <span style="font-size: 12px">Mark as Internal</span>
              </v-tooltip>
            </template>
            <template v-slot:append-outer >
              <v-btn icon @click="addComment"><v-icon>send</v-icon></v-btn>
            </template>
          </v-text-field>
        <!-- <Mentionable
          class="ma-0 pa-0"
          style="width: 100%"
          outlined
          :keys="['@']"
          :items="users"
          @open="onOpen"
          @apply="onApply"
          @search="loadUsers($event)"
          insert-space
        >
         
          <template #no-result>
            <v-list class="my-0 py-0" style="width: 250px" dense>
              <v-list-item>
                <v-list-item-action>
                  <v-progress-circular
                    class="mx-1"
                    size="18"
                    color="primary"
                    indeterminate
                    v-if="loadingUsers"
                  ></v-progress-circular>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="loadingUsers">
                    Loading...
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    No result
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template #item-@="{ item }">
            <v-list class="my-0 py-0" dense>
              <v-list-item
                style="height: 25px; cursor: pointer"
                class="my-0 py-0"
              >
                <v-list-item-avatar>
                  <v-avatar
                    style="cursor: pointer"
                    color="secondary"
                    size="32"
                    class="mt-0 pt-0"
                  >
                    <span
                      v-if="!item.avatar"
                      class="primaryText--text text-h5"
                      >{{
                       $Format.avatarAbbreviation(item.firstname + " " + item.surname)
                      }}</span
                    >
                    <img
                      v-else
                      :src="item.avatar"
                      referrerpolicy="no-referrer"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.firstname }} {{ item.surname }}
                  </v-list-item-title>
                  <v-list-item-subtitle> </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </Mentionable> -->
      </v-card-actions>
    </v-card>

    <v-dialog v-else v-model="open" fullscreen>
      <v-card
        outlined
        class="my-0 py-0 d-flex flex-column"
        :style="{
          'min-height': !$vuetify.breakpoint.mobile ? '60vh' : '100vh',
          width: !$vuetify.breakpoint.mobile ? '450px' : '100vw',
        }"
      >
        <v-card-title class="ma-0 pa-0">
          <v-toolbar flat color="primary">
            <v-toolbar-title> Comments </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              class="mt-5 pt-2"
              color="white"
              style="width: 5px"
              prepend-inner-icon="filter_alt"
              v-model="filterComments"
              placeholder="Filter"
              :items="['All', 'Internal', 'External']"
              flat
              dense
            ></v-select>
            <v-btn
              v-if="$vuetify.breakpoint.mobile"
              text
              @click="$emit('close')"
              >X</v-btn
            >
          </v-toolbar>
        </v-card-title>

        <v-card-text
          style="min-height: 25vh; max-height: 100%; overflow-y: auto"
          class="mx-0 px-0"
          v-loading="loadingComments"
          v-chat-scroll
          :key="commentKey"
        >
          <v-list dense class="mx-0 px-0">
            <div v-for="(comment, index) in commentList" :key="comment.id">
              <v-list-item
                v-if="comment.userId != $store.state.user.id"
                class="py-0 my-0"
                :id="'comment_' + index"
                style="
                  border-radius: 20px;
                  background-color: lightgrey !important;
                "
              >
                <v-list-item-avatar>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer"
                        color="secondary"
                        size="48"
                        class="mt-0 pt-0"
                      >
                        <span
                          v-if="!comment.user.avatar"
                          class="primaryText--text text-h5"
                          >{{
                           $Format.avatarAbbreviation(
                              comment.user.firstname +
                                " " +
                                comment.user.surname
                            )
                          }}</span
                        >
                        <img
                          v-else
                          :src="comment.user.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </template>
                    <span style="font-size: 12px"
                      >{{ comment.user.firstname }}
                      {{ comment.user.surname }}</span
                    >
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ comment.comment }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ comment.organisation.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <i style="font-size: 12px; color: grey">{{
                      formatTime(comment.createdAt)
                    }}</i>
                    <v-chip
                      style="right: 0"
                      outlined
                      v-if="comment.internal"
                      x-small
                      color="blue"
                      class="white--text ml-5"
                      >Internal</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else class="py-0 my-0" :id="'comment_' + index">
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">
                    {{ comment.comment }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ comment.organisation.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <i style="font-size: 12px; color: grey">{{
                      formatTime(comment.createdAt)
                    }}</i>
                    <v-chip
                      style="right: 0"
                      outlined
                      v-if="comment.internal"
                      x-small
                      color="blue"
                      class="white--text ml-5"
                      >Internal</v-chip
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs"
                      v-on="on"
                      style="cursor: pointer"
                      color="secondary"
                      size="48"
                      class="mt-0 pt-0"
                    >
                      <span
                        v-if="!comment.user.avatar"
                        class="primaryText--text text-h5"
                        >{{
                         $Format.avatarAbbreviation(
                            comment.user.firstname + " " + comment.user.surname
                          )
                        }}</span
                      >
                      <img
                        v-else
                        :src="comment.user.avatar"
                        referrerpolicy="no-referrer"
                      />
                    </v-avatar>
                  </template>
                  <span style="font-size: 12px"
                    >{{ comment.user.firstname }}
                    {{ comment.user.surname }}</span
                  >
                </v-tooltip>
              </v-list-item-avatar>
            </div>

            <v-list-item v-if="commentList.length == 0 && !loadingComments">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"> No comments. </span>
              </v-list-item-content></v-list-item
            >
          </v-list>
          <!-- <v-divider></v-divider> -->

          <!-- <Mentionable
              class="ma-0 pa-0"
              style="width: 100%"
              outlined
              :keys="['@']"
              :items="users"
              @open="onOpen"
              @apply="onApply"
              @search="loadUsers($event)"
              insert-space
            >
              <v-textarea
                rows="2"
                solo
                flat
                class="primaryText--text ma-0 pa-0 mt-2"
                placeholder="Add your comment..."
                v-model="comment"
              />
              <template #no-result>
                <v-list class="my-0 py-0" style="width: 250px" dense>
                  <v-list-item>
                    <v-list-item-action>
                      <v-progress-circular
                        class="mx-1"
                        size="18"
                        color="primary"
                        indeterminate
                        v-if="loadingUsers"
                      ></v-progress-circular>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="loadingUsers">
                        Loading...
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        No result
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <template #item-@="{ item }">
                <v-list class="my-0 py-0" dense>
                  <v-list-item
                    style="height: 25px; cursor: pointer"
                    class="my-0 py-0"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        style="cursor: pointer"
                        color="secondary"
                        size="32"
                        class="mt-0 pt-0"
                      >
                        <span
                          v-if="!item.avatar"
                          class="primaryText--text text-h5"
                          >{{
                           $Format.avatarAbbreviation(
                              item.firstname + " " + item.surname
                            )
                          }}</span
                        >
                        <img
                          v-else
                          :src="item.avatar"
                          referrerpolicy="no-referrer"
                        />
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.firstname }} {{ item.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle> </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </Mentionable>

            <v-col class="my-0 py-0 text-right">
              <v-row align="center" justify="end">
                <v-checkbox
                  v-model="internalComment"
                  label="Internal"
                  class="mr-2"
                ></v-checkbox>
                <v-btn
                  color="primary"
                  class="mt-0 pt-0"
                  style="right: 0"
                  @click="addComment()"
                  text
                  >Comment</v-btn
                >
              </v-row>
            </v-col> -->
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="my-0 py-0">
          <v-text-field
            style="width: 100%"
            class="my-0 py-0"
            v-model="comment"
            outlined
            clearable
            placeholder="Message"
            rounded
            type="text"
            append-outer-icon="send"
            v-on:keyup.enter="addComment"
            @click:append-outer="addComment"
          >
          </v-text-field>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
// import "floating-vue/dist/style.css";
export default {
  props: ["item", "open", "consigneeProfileComments", "loadingComments"],
  components: {
    // Mentionable,
  },
  data: () => ({
    // consigneeProfileComments: [],
    comment: null,
    commentKey: 0,
    commentMentions: [],
    filterComments: null,
    internalComment: true,
    unreadMessages: 0,
  }),
  watch: {
    // "item.id": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.getComments();
    //       this.$socket.onmessage = (data) => {
    //   let message = JSON.parse(data.data);
    //   if(message)
    //     this.handleMessage(message)
    // };
    //     }
    //   },
    // },

    filterComments() {
      this.commentKey++;
    },
  },
  computed: {
    commentList() {
      let result = this.consigneeProfileComments;
      if (this.filterComments == "Internal") {
        result = result.filter((x) => x.internal);
      } else if (this.filterComments == "External") {
        result = result.filter((x) => !x.internal);
      }
      return result;
    },
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  methods: {

    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "support",
          type: "text",
          id: Math.random(),
          data: { text },
        });
      }
    },
    handleTyping(text) {
      this.showTypingIndicator =
        text.length > 0
          ? this.participants[this.participants.length - 1].id
          : "";
    },
    onMessageWasSent(message) {
      this.messageList = [
        ...this.messageList,
        Object.assign({}, message, { id: Math.random() }),
      ];
    },
    playNotification() {
      var audioFile = new Audio("https://cdn.loglive.io/sounds/pristine.mp3");
      audioFile.play();
    },
    // onOpen(key) {
    //   this.items = key === "@" ? users : issues;
    // },

    // onApply(item, key, replacedWith) {
    //   console.log(item, `has been replaced with ${replacedWith}`);
    // },
    async addComment() {
      if (this.comment) {
        this.commentMentions = this.commentMentions.filter((x) =>
          this.comment.includes(x.value)
        );
        let obj = {
          comment: this.comment,
          consigneeProfileId: this.item.id,
          internal: this.internalComment,
          user: {
            firstname: this.$store.state.user.firstname,
            surname: this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            emailAddress: this.$store.state.user.emailAddress,
          },
          userId: this.$store.state.user.id,
          organisation: this.$store.state.currentOrg,
          createdAt: new Date(),
          consigneeProfileCommentMentions: this.commentMentions,
        };
        this.$emit("newMessage", obj);
        if (this.item.id) {
          this.comment = null;
          await this.$API.createConsigneeProfileComment(obj).then((result) => {
            this.$emit("updateMessage", result);

            this.$socket.send(
              JSON.stringify({
                action: "sendmessage_room",
                room: "consigneeProfile_" + this.item.id,
                message: {
                  type: "message",
                  room: "consigneeProfile_" + this.item.id,
                  data: result,
                },
              })
            );
          });
        }
        this.commentKey++;
        this.comment = null;
        this.commentMentions = [];
      }
    },
    formatTime(time) {
      let result = null;
      if (time) {
        result = dateFormat(new Date(time), "HH:MM dd mmmm yyyy");
      }
      return result;
    },
  },
};
</script>
